.accordion {
	color: #444;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	text-align: left;
	transition: all 0.3s;
}
.font-b {
	font-weight: bold;
}
.font-i {
	font-style: italic;
}
.page-wrapper {
  width: 80%;
  margin: 20px auto;
}
.page-title {
  font-family: "Gotham Pro Medium";
  font-size: 2.5em;
  color: #152844;
  padding-bottom: 16px;
  margin: 0;
}
input {
  transition: all 0.3s;
}
input.hide-caret {
	border: none;
    color: transparent;
    display: inline-block;
    text-shadow: 0 0 0 rgb(5, 5, 5);
  }

input.invalid,
textarea.invalid {
  border: 1px solid #ed1c24 !important;
}

input:disabled, input:read-only {
	cursor: no-drop;
}
textarea:disabled, textarea:read-only {
	cursor: no-drop;
}
.input-drop img,
.input-drop .checkboxLabel:before,
.input-drop div,
.input-drop span,
.input-drop content,
.input-drop input,
.input-drop label {
  cursor: no-drop !important;
}

.register-code-submit {
  width: 170px;
  margin-left: 10px;
  display: inline-block;
}
.register-input-submit {
  width: calc(100% - 180px);
  display: inline-block;
}

.input-ref-container {
  margin-left: 21.75rem;
}

.input-ref-text {
  font-size: 12.5px;
  opacity: 0.75;
}

.input-hint-text {
  font-size: 12px;
  opacity: 0.75;
}

.input-hint-container {
	padding: 9px 10px;
}
.color-red {
  color: #ef0000 !important;
}
.error-item {
  margin: 8px 8px 0px 0px;
  font-size: 14px;
  text-align: left;
  color: #ef0000;
}
.space-nowrap {
  white-space: nowrap;
}
.error-wrapper.inline-wrap .error-item {
  white-space: nowrap;
}
.error-wrapper {
  transition: all 0.3s;
}

.download-title {
  font-size: 16px;
  color: #b10762;
  text-decoration: underline;
  cursor: pointer;
}
.input-hint {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;
	border-radius: 3px;
	padding: 0px;
	transition: all 0.3s;
	z-index: 1000;
	opacity: 0;
	max-height: 0px;
	overflow: hidden;
}

.input-hint.active, .input-wrapper-hint:hover .input-hint {
	opacity: 1;
	max-height: 1000px;
	overflow: unset;
}
.p-absolute {
	position: absolute;
}
.hint-arrow {
  width: 10px;
  height: 10px;
  background-color: #fdfdfe;
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: rotate(45deg);
}
.brocker-accept-sign {
    padding-left: 36px;
}
a, a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}
button:disabled, button[disabled] {
    opacity: 0.75;
    cursor: no-drop !important;
}
button.button.unfilled {
    background-color: transparent;
    border: none;
    color: #b10762;
}
h1 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 40px;
}
.decoration-under {
  text-decoration: underline;
}
button.button {
  font-family: "Gotham Pro";
  font-weight: bold;
  font-size: 18px;
  border-radius: 33px;
  text-align: center;
  cursor: pointer;
  outline: none;
  padding: 15px 30px;
  height: 50px;
  text-decoration: none;
}
button.button.main {
  background-color: #b10762;
  color: white;
  text-decoration: none;
  border: none;
  text-align: center;
}
button.button.border {
  background-color: white;
  color: #b10762;
  text-decoration: none;
  border: 2px solid #b10762;
}

.color-blue {
  color: #b10762 !important
}

.color-white {
  color: #ffffff !important;
}

.abs-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.field-with-mark-disabled {
	width: calc(96% - 150px);
}
.field-mark-disabled {
	width: 150px;
}
.p-relative {
	position: relative !important;
}
.pointer {
  cursor: pointer !important;
}
.d-none {
	display: none;
}
.scroll {
	overflow-y: scroll;
}
.cur-drop {
	cursor: no-drop !important;
}
.cur-def {
	cursor: default;
}
.d-inline-block {
	display: inline-block !important;
}
.disabled {
  cursor: no-drop !important;
  opacity: 0.75;
}
.f-left {
	float: left;
}
.f-right {
	float: right;
}
.m-auto {
	margin: 0 auto !important;
}
.w-max {
	width: max-content;
}
.t-align-center {
	text-align: center !important;
}
.t-align-left {
	text-align: left !important;
}
.t-align-right {
	text-align: right !important;
}
.t-upper {
	text-transform: uppercase;
}
.w-0 {
	width: 0% !important;
}
.w-1 {
	width: 1% !important;
}
.w-2 {
	width: 2% !important;
}
.w-3 {
	width: 3% !important;
}
.w-4 {
	width: 4% !important;
}
.w-5 {
	width: 5% !important;
}
.w-6 {
	width: 6% !important;
}
.w-7 {
	width: 7% !important;
}
.w-8 {
	width: 8% !important;
}
.w-9 {
	width: 9% !important;
}
.w-10 {
	width: 10% !important;
}
.w-11 {
	width: 11% !important;
}
.w-12 {
	width: 12% !important;
}
.w-13 {
	width: 13% !important;
}
.w-14 {
	width: 14% !important;
}
.w-15 {
	width: 15% !important;
}
.w-16 {
	width: 16% !important;
}
.w-17 {
	width: 17% !important;
}
.w-18 {
	width: 18% !important;
}
.w-19 {
	width: 19% !important;
}
.w-20 {
	width: 20% !important;
}
.w-21 {
	width: 21% !important;
}
.w-22 {
	width: 22% !important;
}
.w-23 {
	width: 23% !important;
}
.w-24 {
	width: 24% !important;
}
.w-25 {
	width: 25% !important;
}
.w-26 {
	width: 26% !important;
}
.w-27 {
	width: 27% !important;
}
.w-28 {
	width: 28% !important;
}
.w-29 {
	width: 29% !important;
}
.w-30 {
	width: 30% !important;
}
.w-31 {
	width: 31% !important;
}
.w-32 {
	width: 32% !important;
}
.w-33 {
	width: 33% !important;
}
.w-34 {
	width: 34% !important;
}
.w-35 {
	width: 35% !important;
}
.w-36 {
	width: 36% !important;
}
.w-37 {
	width: 37% !important;
}
.w-38 {
	width: 38% !important;
}
.w-39 {
	width: 39% !important;
}
.w-40 {
	width: 40% !important;
}
.w-41 {
	width: 41% !important;
}
.w-42 {
	width: 42% !important;
}
.w-43 {
	width: 43% !important;
}
.w-44 {
	width: 44% !important;
}
.w-45 {
	width: 45% !important;
}
.w-46 {
	width: 46% !important;
}
.w-47 {
	width: 47% !important;
}
.w-48 {
	width: 48% !important;
}
.w-49 {
	width: 49% !important;
}
.w-50 {
	width: 50% !important;
}
.w-51 {
	width: 51% !important;
}
.w-52 {
	width: 52% !important;
}
.w-53 {
	width: 53% !important;
}
.w-54 {
	width: 54% !important;
}
.w-55 {
	width: 55% !important;
}
.w-56 {
	width: 56% !important;
}
.w-57 {
	width: 57% !important;
}
.w-58 {
	width: 58% !important;
}
.w-59 {
	width: 59% !important;
}
.w-60 {
	width: 60% !important;
}
.w-61 {
	width: 61% !important;
}
.w-62 {
	width: 62% !important;
}
.w-63 {
	width: 63% !important;
}
.w-64 {
	width: 64% !important;
}
.w-65 {
	width: 65% !important;
}
.w-66 {
	width: 66% !important;
}
.w-67 {
	width: 67% !important;
}
.w-68 {
	width: 68% !important;
}
.w-69 {
	width: 69% !important;
}
.w-70 {
	width: 70% !important;
}
.w-71 {
	width: 71% !important;
}
.w-72 {
	width: 72% !important;
}
.w-73 {
	width: 73% !important;
}
.w-74 {
	width: 74% !important;
}
.w-75 {
	width: 75% !important;
}
.w-76 {
	width: 76% !important;
}
.w-77 {
	width: 77% !important;
}
.w-78 {
	width: 78% !important;
}
.w-79 {
	width: 79% !important;
}
.w-80 {
	width: 80% !important;
}
.w-81 {
	width: 81% !important;
}
.w-82 {
	width: 82% !important;
}
.w-83 {
	width: 83% !important;
}
.w-84 {
	width: 84% !important;
}
.w-85 {
	width: 85% !important;
}
.w-86 {
	width: 86% !important;
}
.w-87 {
	width: 87% !important;
}
.w-88 {
	width: 88% !important;
}
.w-89 {
	width: 89% !important;
}
.w-90 {
	width: 90% !important;
}
.w-91 {
	width: 91% !important;
}
.w-92 {
	width: 92% !important;
}
.w-93 {
	width: 93% !important;
}
.w-94 {
	width: 94% !important;
}
.w-95 {
	width: 95% !important;
}
.w-96 {
	width: 96% !important;
}
.w-97 {
	width: 97% !important;
}
.w-98 {
	width: 98% !important;
}
.w-99 {
	width: 99% !important;
}
.w-100 {
	width: 100% !important;
}
.f-01 {
	font-size: 1px !important;
}
.f-02 {
	font-size: 2px !important;
}
.f-03 {
	font-size: 3px !important;
}
.f-04 {
	font-size: 4px !important;
}
.f-05 {
	font-size: 5px !important;
}
.f-06 {
	font-size: 6px !important;
}
.f-07 {
	font-size: 7px !important;
}
.f-08 {
	font-size: 8px !important;
}
.f-09 {
	font-size: 9px !important;
}
.f-10 {
	font-size: 10px !important;
}
.f-11 {
	font-size: 11px !important;
}
.f-12 {
	font-size: 12px !important;
}
.f-13 {
	font-size: 13px !important;
}
.f-14 {
	font-size: 14px !important;
}
.f-15 {
	font-size: 15px !important;
}
.f-16 {
	font-size: 16px !important;
}
.f-17 {
	font-size: 17px !important;
}
.f-18 {
	font-size: 18px !important;
}
.f-19 {
	font-size: 19px !important;
}
.f-20 {
	font-size: 20px !important;
}
.f-21 {
	font-size: 21px !important;
}
.f-22 {
	font-size: 22px !important;
}
.f-23 {
	font-size: 23px !important;
}
.f-24 {
	font-size: 24px !important;
}
.f-25 {
	font-size: 25px !important;
}
.f-26 {
	font-size: 26px !important;
}
.f-27 {
	font-size: 27px !important;
}
.f-28 {
	font-size: 28px !important;
}
.f-29 {
	font-size: 29px !important;
}
.f-30 {
	font-size: 30px !important;
}
.f-31 {
	font-size: 31px !important;
}
.f-32 {
	font-size: 32px !important;
}
.f-33 {
	font-size: 33px !important;
}
.f-34 {
	font-size: 34px !important;
}
.f-35 {
	font-size: 35px !important;
}
.f-36 {
	font-size: 36px !important;
}
.f-37 {
	font-size: 37px !important;
}
.f-38 {
	font-size: 38px !important;
}
.f-39 {
	font-size: 39px !important;
}
.f-40 {
	font-size: 40px !important;
}
.f-41 {
	font-size: 41px !important;
}
.f-42 {
	font-size: 42px !important;
}
.f-43 {
	font-size: 43px !important;
}
.f-44 {
	font-size: 44px !important;
}
.f-45 {
	font-size: 45px !important;
}
.f-46 {
	font-size: 46px !important;
}
.f-47 {
	font-size: 47px !important;
}
.f-48 {
	font-size: 48px !important;
}
.f-49 {
	font-size: 49px !important;
}
.f-50 {
	font-size: 50px !important;
}
.f-51 {
	font-size: 51px !important;
}
.f-52 {
	font-size: 52px !important;
}
.f-53 {
	font-size: 53px !important;
}
.f-54 {
	font-size: 54px !important;
}
.f-55 {
	font-size: 55px !important;
}
.f-56 {
	font-size: 56px !important;
}
.f-57 {
	font-size: 57px !important;
}
.f-58 {
	font-size: 58px !important;
}
.f-59 {
	font-size: 59px !important;
}
.f-60 {
	font-size: 60px !important;
}
.mt-0 {
	margin-top: 0px !important;
}
.mt-1 {
	margin-top: 1px !important;
}
.mt-2 {
	margin-top: 2px !important;
}
.mt-3 {
	margin-top: 3px !important;
}
.mt-4 {
	margin-top: 4px !important;
}
.mt-5 {
	margin-top: 5px !important;
}
.mt-6 {
	margin-top: 6px !important;
}
.mt-7 {
	margin-top: 7px !important;
}
.mt-8 {
	margin-top: 8px !important;
}
.mt-9 {
	margin-top: 9px !important;
}
.mt-10 {
	margin-top: 10px !important;
}
.mt-11 {
	margin-top: 11px !important;
}
.mt-12 {
	margin-top: 12px !important;
}
.mt-13 {
	margin-top: 13px !important;
}
.mt-14 {
	margin-top: 14px !important;
}
.mt-15 {
	margin-top: 15px !important;
}
.mt-16 {
	margin-top: 16px !important;
}
.mt-17 {
	margin-top: 17px !important;
}
.mt-18 {
	margin-top: 18px !important;
}
.mt-19 {
	margin-top: 19px !important;
}
.mt-20 {
	margin-top: 20px !important;
}
.mt-21 {
	margin-top: 21px !important;
}
.mt-22 {
	margin-top: 22px !important;
}
.mt-23 {
	margin-top: 23px !important;
}
.mt-24 {
	margin-top: 24px !important;
}
.mt-25 {
	margin-top: 25px !important;
}
.mt-26 {
	margin-top: 26px !important;
}
.mt-27 {
	margin-top: 27px !important;
}
.mt-28 {
	margin-top: 28px !important;
}
.mt-29 {
	margin-top: 29px !important;
}
.mt-30 {
	margin-top: 30px !important;
}
.mt-31 {
	margin-top: 31px !important;
}
.mt-32 {
	margin-top: 32px !important;
}
.mt-33 {
	margin-top: 33px !important;
}
.mt-34 {
	margin-top: 34px !important;
}
.mt-35 {
	margin-top: 35px !important;
}
.mt-36 {
	margin-top: 36px !important;
}
.mt-37 {
	margin-top: 37px !important;
}
.mt-38 {
	margin-top: 38px !important;
}
.mt-39 {
	margin-top: 39px !important;
}
.mt-40 {
	margin-top: 40px !important;
}
.mt-41 {
	margin-top: 41px !important;
}
.mt-42 {
	margin-top: 42px !important;
}
.mt-43 {
	margin-top: 43px !important;
}
.mt-44 {
	margin-top: 44px !important;
}
.mt-45 {
	margin-top: 45px !important;
}
.mt-46 {
	margin-top: 46px !important;
}
.mt-47 {
	margin-top: 47px !important;
}
.mt-48 {
	margin-top: 48px !important;
}
.mt-49 {
	margin-top: 49px !important;
}
.mt-50 {
	margin-top: 50px !important;
}
.mt-51 {
	margin-top: 51px !important;
}
.mt-52 {
	margin-top: 52px !important;
}
.mt-53 {
	margin-top: 53px !important;
}
.mt-54 {
	margin-top: 54px !important;
}
.mt-55 {
	margin-top: 55px !important;
}
.mt-56 {
	margin-top: 56px !important;
}
.mt-57 {
	margin-top: 57px !important;
}
.mt-58 {
	margin-top: 58px !important;
}
.mt-59 {
	margin-top: 59px !important;
}
.mt-60 {
	margin-top: 60px !important;
}
.mt-100 {
	margin-top: 100px !important;
}
.mt-140 {
	margin-top: 140px !important;
}
.mb-0 {
	margin-bottom: 0px !important;
}
.mb-1 {
	margin-bottom: 1px !important;
}
.mb-2 {
	margin-bottom: 2px !important;
}
.mb-3 {
	margin-bottom: 3px !important;
}
.mb-4 {
	margin-bottom: 4px !important;
}
.mb-5 {
	margin-bottom: 5px !important;
}
.mb-6 {
	margin-bottom: 6px !important;
}
.mb-7 {
	margin-bottom: 7px !important;
}
.mb-8 {
	margin-bottom: 8px !important;
}
.mb-9 {
	margin-bottom: 9px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.mb-11 {
	margin-bottom: 11px !important;
}
.mb-12 {
	margin-bottom: 12px !important;
}
.mb-13 {
	margin-bottom: 13px !important;
}
.mb-14 {
	margin-bottom: 14px !important;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mb-16 {
	margin-bottom: 16px !important;
}
.mb-17 {
	margin-bottom: 17px !important;
}
.mb-18 {
	margin-bottom: 18px !important;
}
.mb-19 {
	margin-bottom: 19px !important;
}
.mb-20 {
	margin-bottom: 20px !important;
}
.mb-21 {
	margin-bottom: 21px !important;
}
.mb-22 {
	margin-bottom: 22px !important;
}
.mb-23 {
	margin-bottom: 23px !important;
}
.mb-24 {
	margin-bottom: 24px !important;
}
.mb-25 {
	margin-bottom: 25px !important;
}
.mb-26 {
	margin-bottom: 26px !important;
}
.mb-27 {
	margin-bottom: 27px !important;
}
.mb-28 {
	margin-bottom: 28px !important;
}
.mb-29 {
	margin-bottom: 29px !important;
}
.mb-30 {
	margin-bottom: 30px !important;
}
.mb-31 {
	margin-bottom: 31px !important;
}
.mb-32 {
	margin-bottom: 32px !important;
}
.mb-33 {
	margin-bottom: 33px !important;
}
.mb-34 {
	margin-bottom: 34px !important;
}
.mb-35 {
	margin-bottom: 35px !important;
}
.mb-36 {
	margin-bottom: 36px !important;
}
.mb-37 {
	margin-bottom: 37px !important;
}
.mb-38 {
	margin-bottom: 38px !important;
}
.mb-39 {
	margin-bottom: 39px !important;
}
.mb-40 {
	margin-bottom: 40px !important;
}
.mb-41 {
	margin-bottom: 41px !important;
}
.mb-42 {
	margin-bottom: 42px !important;
}
.mb-43 {
	margin-bottom: 43px !important;
}
.mb-44 {
	margin-bottom: 44px !important;
}
.mb-45 {
	margin-bottom: 45px !important;
}
.mb-46 {
	margin-bottom: 46px !important;
}
.mb-47 {
	margin-bottom: 47px !important;
}
.mb-48 {
	margin-bottom: 48px !important;
}
.mb-49 {
	margin-bottom: 49px !important;
}
.mb-50 {
	margin-bottom: 50px !important;
}
.mb-51 {
	margin-bottom: 51px !important;
}
.mb-52 {
	margin-bottom: 52px !important;
}
.mb-53 {
	margin-bottom: 53px !important;
}
.mb-54 {
	margin-bottom: 54px !important;
}
.mb-55 {
	margin-bottom: 55px !important;
}
.mb-56 {
	margin-bottom: 56px !important;
}
.mb-57 {
	margin-bottom: 57px !important;
}
.mb-58 {
	margin-bottom: 58px !important;
}
.mb-59 {
	margin-bottom: 59px !important;
}
.mb-60 {
	margin-bottom: 60px !important;
}
.mb-100 {
	margin-bottom: 100px !important;
}
.p-0 {
	padding: 0px !important;
}
.p-1 {
	padding: 1px !important;
}
.p-2 {
	padding: 2px !important;
}
.p-3 {
	padding: 3px !important;
}
.p-4 {
	padding: 4px !important;
}
.p-5 {
	padding: 5px !important;
}
.p-6 {
	padding: 6px !important;
}
.p-7 {
	padding: 7px !important;
}
.p-8 {
	padding: 8px !important;
}
.p-9 {
	padding: 9px !important;
}
.p-10 {
	padding: 10px !important;
}
.p-11 {
	padding: 11px !important;
}
.p-12 {
	padding: 12px !important;
}
.p-13 {
	padding: 13px !important;
}
.p-14 {
	padding: 14px !important;
}
.p-15 {
	padding: 15px !important;
}
.p-16 {
	padding: 16px !important;
}
.p-17 {
	padding: 17px !important;
}
.p-18 {
	padding: 18px !important;
}
.p-19 {
	padding: 19px !important;
}
.p-20 {
	padding: 20px !important;
}
.p-21 {
	padding: 21px !important;
}
.p-22 {
	padding: 22px !important;
}
.p-23 {
	padding: 23px !important;
}
.p-24 {
	padding: 24px !important;
}
.p-25 {
	padding: 25px !important;
}
.p-26 {
	padding: 26px !important;
}
.p-27 {
	padding: 27px !important;
}
.p-28 {
	padding: 28px !important;
}
.p-29 {
	padding: 29px !important;
}
.p-30 {
	padding: 30px !important;
}
.p-31 {
	padding: 31px !important;
}
.p-32 {
	padding: 32px !important;
}
.p-33 {
	padding: 33px !important;
}
.p-34 {
	padding: 34px !important;
}
.p-35 {
	padding: 35px !important;
}
.p-36 {
	padding: 36px !important;
}
.p-37 {
	padding: 37px !important;
}
.p-38 {
	padding: 38px !important;
}
.p-39 {
	padding: 39px !important;
}
.p-40 {
	padding: 40px !important;
}
.p-41 {
	padding: 41px !important;
}
.p-42 {
	padding: 42px !important;
}
.p-43 {
	padding: 43px !important;
}
.p-44 {
	padding: 44px !important;
}
.p-45 {
	padding: 45px !important;
}
.p-46 {
	padding: 46px !important;
}
.p-47 {
	padding: 47px !important;
}
.p-48 {
	padding: 48px !important;
}
.p-49 {
	padding: 49px !important;
}
.p-50 {
	padding: 50px !important;
}
.p-51 {
	padding: 51px !important;
}
.p-52 {
	padding: 52px !important;
}
.p-53 {
	padding: 53px !important;
}
.p-54 {
	padding: 54px !important;
}
.p-55 {
	padding: 55px !important;
}
.p-56 {
	padding: 56px !important;
}
.p-57 {
	padding: 57px !important;
}
.p-58 {
	padding: 58px !important;
}
.p-59 {
	padding: 59px !important;
}
.p-60 {
	padding: 60px !important;
}
.pt-0 {
	padding-top: 0px !important;
}
.pt-1 {
	padding-top: 1px !important;
}
.pt-2 {
	padding-top: 2px !important;
}
.pt-3 {
	padding-top: 3px !important;
}
.pt-4 {
	padding-top: 4px !important;
}
.pt-5 {
	padding-top: 5px !important;
}
.pt-6 {
	padding-top: 6px !important;
}
.pt-7 {
	padding-top: 7px !important;
}
.pt-8 {
	padding-top: 8px !important;
}
.pt-9 {
	padding-top: 9px !important;
}
.pt-10 {
	padding-top: 10px !important;
}
.pt-11 {
	padding-top: 11px !important;
}
.pt-12 {
	padding-top: 12px !important;
}
.pt-13 {
	padding-top: 13px !important;
}
.pt-14 {
	padding-top: 14px !important;
}
.pt-15 {
	padding-top: 15px !important;
}
.pt-16 {
	padding-top: 16px !important;
}
.pt-17 {
	padding-top: 17px !important;
}
.pt-18 {
	padding-top: 18px !important;
}
.pt-19 {
	padding-top: 19px !important;
}
.pt-20 {
	padding-top: 20px !important;
}
.pt-21 {
	padding-top: 21px !important;
}
.pt-22 {
	padding-top: 22px !important;
}
.pt-23 {
	padding-top: 23px !important;
}
.pt-24 {
	padding-top: 24px !important;
}
.pt-25 {
	padding-top: 25px !important;
}
.pt-26 {
	padding-top: 26px !important;
}
.pt-27 {
	padding-top: 27px !important;
}
.pt-28 {
	padding-top: 28px !important;
}
.pt-29 {
	padding-top: 29px !important;
}
.pt-30 {
	padding-top: 30px !important;
}
.pt-31 {
	padding-top: 31px !important;
}
.pt-32 {
	padding-top: 32px !important;
}
.pt-33 {
	padding-top: 33px !important;
}
.pt-34 {
	padding-top: 34px !important;
}
.pt-35 {
	padding-top: 35px !important;
}
.pt-36 {
	padding-top: 36px !important;
}
.pt-37 {
	padding-top: 37px !important;
}
.pt-38 {
	padding-top: 38px !important;
}
.pt-39 {
	padding-top: 39px !important;
}
.pt-40 {
	padding-top: 40px !important;
}
.pt-41 {
	padding-top: 41px !important;
}
.pt-42 {
	padding-top: 42px !important;
}
.pt-43 {
	padding-top: 43px !important;
}
.pt-44 {
	padding-top: 44px !important;
}
.pt-45 {
	padding-top: 45px !important;
}
.pt-46 {
	padding-top: 46px !important;
}
.pt-47 {
	padding-top: 47px !important;
}
.pt-48 {
	padding-top: 48px !important;
}
.pt-49 {
	padding-top: 49px !important;
}
.pt-50 {
	padding-top: 50px !important;
}
.pt-51 {
	padding-top: 51px !important;
}
.pt-52 {
	padding-top: 52px !important;
}
.pt-53 {
	padding-top: 53px !important;
}
.pt-54 {
	padding-top: 54px !important;
}
.pt-55 {
	padding-top: 55px !important;
}
.pt-56 {
	padding-top: 56px !important;
}
.pt-57 {
	padding-top: 57px !important;
}
.pt-58 {
	padding-top: 58px !important;
}
.pt-59 {
	padding-top: 59px !important;
}
.pt-60 {
	padding-top: 60px !important;
}
.pb-0 {
	padding-bottom: 0px !important;
}
.pb-1 {
	padding-bottom: 1px !important;
}
.pb-2 {
	padding-bottom: 2px !important;
}
.pb-3 {
	padding-bottom: 3px !important;
}
.pb-4 {
	padding-bottom: 4px !important;
}
.pb-5 {
	padding-bottom: 5px !important;
}
.pb-6 {
	padding-bottom: 6px !important;
}
.pb-7 {
	padding-bottom: 7px !important;
}
.pb-8 {
	padding-bottom: 8px !important;
}
.pb-9 {
	padding-bottom: 9px !important;
}
.pb-10 {
	padding-bottom: 10px !important;
}
.pb-11 {
	padding-bottom: 11px !important;
}
.pb-12 {
	padding-bottom: 12px !important;
}
.pb-13 {
	padding-bottom: 13px !important;
}
.pb-14 {
	padding-bottom: 14px !important;
}
.pb-15 {
	padding-bottom: 15px !important;
}
.pb-16 {
	padding-bottom: 16px !important;
}
.pb-17 {
	padding-bottom: 17px !important;
}
.pb-18 {
	padding-bottom: 18px !important;
}
.pb-19 {
	padding-bottom: 19px !important;
}
.pb-20 {
	padding-bottom: 20px !important;
}
.pb-21 {
	padding-bottom: 21px !important;
}
.pb-22 {
	padding-bottom: 22px !important;
}
.pb-23 {
	padding-bottom: 23px !important;
}
.pb-24 {
	padding-bottom: 24px !important;
}
.pb-25 {
	padding-bottom: 25px !important;
}
.pb-26 {
	padding-bottom: 26px !important;
}
.pb-27 {
	padding-bottom: 27px !important;
}
.pb-28 {
	padding-bottom: 28px !important;
}
.pb-29 {
	padding-bottom: 29px !important;
}
.pb-30 {
	padding-bottom: 30px !important;
}
.pb-31 {
	padding-bottom: 31px !important;
}
.pb-32 {
	padding-bottom: 32px !important;
}
.pb-33 {
	padding-bottom: 33px !important;
}
.pb-34 {
	padding-bottom: 34px !important;
}
.pb-35 {
	padding-bottom: 35px !important;
}
.pb-36 {
	padding-bottom: 36px !important;
}
.pb-37 {
	padding-bottom: 37px !important;
}
.pb-38 {
	padding-bottom: 38px !important;
}
.pb-39 {
	padding-bottom: 39px !important;
}
.pb-40 {
	padding-bottom: 40px !important;
}
.pb-41 {
	padding-bottom: 41px !important;
}
.pb-42 {
	padding-bottom: 42px !important;
}
.pb-43 {
	padding-bottom: 43px !important;
}
.pb-44 {
	padding-bottom: 44px !important;
}
.pb-45 {
	padding-bottom: 45px !important;
}
.pb-46 {
	padding-bottom: 46px !important;
}
.pb-47 {
	padding-bottom: 47px !important;
}
.pb-48 {
	padding-bottom: 48px !important;
}
.pb-49 {
	padding-bottom: 49px !important;
}
.pb-50 {
	padding-bottom: 50px !important;
}
.pb-51 {
	padding-bottom: 51px !important;
}
.pb-52 {
	padding-bottom: 52px !important;
}
.pb-53 {
	padding-bottom: 53px !important;
}
.pb-54 {
	padding-bottom: 54px !important;
}
.pb-55 {
	padding-bottom: 55px !important;
}
.pb-56 {
	padding-bottom: 56px !important;
}
.pb-57 {
	padding-bottom: 57px !important;
}
.pb-58 {
	padding-bottom: 58px !important;
}
.pb-59 {
	padding-bottom: 59px !important;
}
.pb-60 {
	padding-bottom: 60px !important;
}
.ml-0 {
	margin-left: 0% !important;
}

.ml-1 {
	margin-left: 1% !important;
}

.ml-2 {
	margin-left: 2% !important;
}

.ml-3 {
	margin-left: 3% !important;
}

.ml-4 {
	margin-left: 4% !important;
}

.ml-5 {
	margin-left: 5% !important;
}

.ml-6 {
	margin-left: 6% !important;
}

.ml-7 {
	margin-left: 7% !important;
}

.ml-8 {
	margin-left: 8% !important;
}

.ml-9 {
	margin-left: 9% !important;
}

.ml-10 {
	margin-left: 10% !important;
}

.ml-11 {
	margin-left: 11% !important;
}

.ml-12 {
	margin-left: 12% !important;
}

.ml-13 {
	margin-left: 13% !important;
}

.ml-14 {
	margin-left: 14% !important;
}

.ml-15 {
	margin-left: 15% !important;
}

.ml-16 {
	margin-left: 16% !important;
}

.ml-17 {
	margin-left: 17% !important;
}

.ml-18 {
	margin-left: 18% !important;
}

.ml-19 {
	margin-left: 19% !important;
}

.ml-20 {
	margin-left: 20% !important;
}

.ml-21 {
	margin-left: 21% !important;
}

.ml-22 {
	margin-left: 22% !important;
}

.ml-23 {
	margin-left: 23% !important;
}

.ml-24 {
	margin-left: 24% !important;
}

.ml-25 {
	margin-left: 25% !important;
}

.ml-26 {
	margin-left: 26% !important;
}

.ml-27 {
	margin-left: 27% !important;
}

.ml-28 {
	margin-left: 28% !important;
}

.ml-29 {
	margin-left: 29% !important;
}

.ml-30 {
	margin-left: 30% !important;
}

.ml-31 {
	margin-left: 31% !important;
}

.ml-32 {
	margin-left: 32% !important;
}

.ml-33 {
	margin-left: 33% !important;
}

.ml-34 {
	margin-left: 34% !important;
}

.ml-35 {
	margin-left: 35% !important;
}

.ml-36 {
	margin-left: 36% !important;
}

.ml-37 {
	margin-left: 37% !important;
}

.ml-38 {
	margin-left: 38% !important;
}

.ml-39 {
	margin-left: 39% !important;
}

.ml-40 {
	margin-left: 40% !important;
}

.ml-41 {
	margin-left: 41% !important;
}

.ml-42 {
	margin-left: 42% !important;
}

.ml-43 {
	margin-left: 43% !important;
}

.ml-44 {
	margin-left: 44% !important;
}

.ml-45 {
	margin-left: 45% !important;
}

.ml-46 {
	margin-left: 46% !important;
}

.ml-47 {
	margin-left: 47% !important;
}

.ml-48 {
	margin-left: 48% !important;
}

.ml-49 {
	margin-left: 49% !important;
}

.ml-50 {
	margin-left: 50% !important;
}

.ml-51 {
	margin-left: 51% !important;
}

.ml-52 {
	margin-left: 52% !important;
}

.ml-53 {
	margin-left: 53% !important;
}

.ml-54 {
	margin-left: 54% !important;
}

.ml-55 {
	margin-left: 55% !important;
}

.ml-56 {
	margin-left: 56% !important;
}

.ml-57 {
	margin-left: 57% !important;
}

.ml-58 {
	margin-left: 58% !important;
}

.ml-59 {
	margin-left: 59% !important;
}

.ml-60 {
	margin-left: 60% !important;
}

.ml-61 {
	margin-left: 61% !important;
}

.ml-62 {
	margin-left: 62% !important;
}

.ml-63 {
	margin-left: 63% !important;
}

.ml-64 {
	margin-left: 64% !important;
}

.ml-65 {
	margin-left: 65% !important;
}

.ml-66 {
	margin-left: 66% !important;
}

.ml-67 {
	margin-left: 67% !important;
}

.ml-68 {
	margin-left: 68% !important;
}

.ml-69 {
	margin-left: 69% !important;
}

.ml-70 {
	margin-left: 70% !important;
}

.ml-71 {
	margin-left: 71% !important;
}

.ml-72 {
	margin-left: 72% !important;
}

.ml-73 {
	margin-left: 73% !important;
}

.ml-74 {
	margin-left: 74% !important;
}

.ml-75 {
	margin-left: 75% !important;
}

.ml-76 {
	margin-left: 76% !important;
}

.ml-77 {
	margin-left: 77% !important;
}

.ml-78 {
	margin-left: 78% !important;
}

.ml-79 {
	margin-left: 79% !important;
}

.ml-80 {
	margin-left: 80% !important;
}

.ml-81 {
	margin-left: 81% !important;
}

.ml-82 {
	margin-left: 82% !important;
}

.ml-83 {
	margin-left: 83% !important;
}

.ml-84 {
	margin-left: 84% !important;
}

.ml-85 {
	margin-left: 85% !important;
}

.ml-86 {
	margin-left: 86% !important;
}

.ml-87 {
	margin-left: 87% !important;
}

.ml-88 {
	margin-left: 88% !important;
}

.ml-89 {
	margin-left: 89% !important;
}

.ml-90 {
	margin-left: 90% !important;
}

.ml-91 {
	margin-left: 91% !important;
}

.ml-92 {
	margin-left: 92% !important;
}

.ml-93 {
	margin-left: 93% !important;
}

.ml-94 {
	margin-left: 94% !important;
}

.ml-95 {
	margin-left: 95% !important;
}

.ml-96 {
	margin-left: 96% !important;
}

.ml-97 {
	margin-left: 97% !important;
}

.ml-98 {
	margin-left: 98% !important;
}

.ml-99 {
	margin-left: 99% !important;
}

.ml-100 {
	margin-left: 100% !important;
}

.mr-0 {
	margin-right: 0% !important;
}

.mr-1 {
	margin-right: 1% !important;
}

.mr-2 {
	margin-right: 2% !important;
}

.mr-3 {
	margin-right: 3% !important;
}

.mr-4 {
	margin-right: 4% !important;
}

.mr-5 {
	margin-right: 5% !important;
}

.mr-6 {
	margin-right: 6% !important;
}

.mr-7 {
	margin-right: 7% !important;
}

.mr-8 {
	margin-right: 8% !important;
}

.mr-9 {
	margin-right: 9% !important;
}

.mr-10 {
	margin-right: 10% !important;
}

.mr-11 {
	margin-right: 11% !important;
}

.mr-12 {
	margin-right: 12% !important;
}

.mr-13 {
	margin-right: 13% !important;
}

.mr-14 {
	margin-right: 14% !important;
}

.mr-15 {
	margin-right: 15% !important;
}

.mr-16 {
	margin-right: 16% !important;
}

.mr-17 {
	margin-right: 17% !important;
}

.mr-18 {
	margin-right: 18% !important;
}

.mr-19 {
	margin-right: 19% !important;
}

.mr-20 {
	margin-right: 20% !important;
}

.mr-21 {
	margin-right: 21% !important;
}

.mr-22 {
	margin-right: 22% !important;
}

.mr-23 {
	margin-right: 23% !important;
}

.mr-24 {
	margin-right: 24% !important;
}

.mr-25 {
	margin-right: 25% !important;
}

.mr-26 {
	margin-right: 26% !important;
}

.mr-27 {
	margin-right: 27% !important;
}

.mr-28 {
	margin-right: 28% !important;
}

.mr-29 {
	margin-right: 29% !important;
}

.mr-30 {
	margin-right: 30% !important;
}

.mr-31 {
	margin-right: 31% !important;
}

.mr-32 {
	margin-right: 32% !important;
}

.mr-33 {
	margin-right: 33% !important;
}

.mr-34 {
	margin-right: 34% !important;
}

.mr-35 {
	margin-right: 35% !important;
}

.mr-36 {
	margin-right: 36% !important;
}

.mr-37 {
	margin-right: 37% !important;
}

.mr-38 {
	margin-right: 38% !important;
}

.mr-39 {
	margin-right: 39% !important;
}

.mr-40 {
	margin-right: 40% !important;
}

.mr-41 {
	margin-right: 41% !important;
}

.mr-42 {
	margin-right: 42% !important;
}

.mr-43 {
	margin-right: 43% !important;
}

.mr-44 {
	margin-right: 44% !important;
}

.mr-45 {
	margin-right: 45% !important;
}

.mr-46 {
	margin-right: 46% !important;
}

.mr-47 {
	margin-right: 47% !important;
}

.mr-48 {
	margin-right: 48% !important;
}

.mr-49 {
	margin-right: 49% !important;
}

.mr-50 {
	margin-right: 50% !important;
}

.mr-51 {
	margin-right: 51% !important;
}

.mr-52 {
	margin-right: 52% !important;
}

.mr-53 {
	margin-right: 53% !important;
}

.mr-54 {
	margin-right: 54% !important;
}

.mr-55 {
	margin-right: 55% !important;
}

.mr-56 {
	margin-right: 56% !important;
}

.mr-57 {
	margin-right: 57% !important;
}

.mr-58 {
	margin-right: 58% !important;
}

.mr-59 {
	margin-right: 59% !important;
}

.mr-60 {
	margin-right: 60% !important;
}

.mr-61 {
	margin-right: 61% !important;
}

.mr-62 {
	margin-right: 62% !important;
}

.mr-63 {
	margin-right: 63% !important;
}

.mr-64 {
	margin-right: 64% !important;
}

.mr-65 {
	margin-right: 65% !important;
}

.mr-66 {
	margin-right: 66% !important;
}

.mr-67 {
	margin-right: 67% !important;
}

.mr-68 {
	margin-right: 68% !important;
}

.mr-69 {
	margin-right: 69% !important;
}

.mr-70 {
	margin-right: 70% !important;
}

.mr-71 {
	margin-right: 71% !important;
}

.mr-72 {
	margin-right: 72% !important;
}

.mr-73 {
	margin-right: 73% !important;
}

.mr-74 {
	margin-right: 74% !important;
}

.mr-75 {
	margin-right: 75% !important;
}

.mr-76 {
	margin-right: 76% !important;
}

.mr-77 {
	margin-right: 77% !important;
}

.mr-78 {
	margin-right: 78% !important;
}

.mr-79 {
	margin-right: 79% !important;
}

.mr-80 {
	margin-right: 80% !important;
}

.mr-81 {
	margin-right: 81% !important;
}

.mr-82 {
	margin-right: 82% !important;
}

.mr-83 {
	margin-right: 83% !important;
}

.mr-84 {
	margin-right: 84% !important;
}

.mr-85 {
	margin-right: 85% !important;
}

.mr-86 {
	margin-right: 86% !important;
}

.mr-87 {
	margin-right: 87% !important;
}

.mr-88 {
	margin-right: 88% !important;
}

.mr-89 {
	margin-right: 89% !important;
}

.mr-90 {
	margin-right: 90% !important;
}

.mr-91 {
	margin-right: 91% !important;
}

.mr-92 {
	margin-right: 92% !important;
}

.mr-93 {
	margin-right: 93% !important;
}

.mr-94 {
	margin-right: 94% !important;
}

.mr-95 {
	margin-right: 95% !important;
}

.mr-96 {
	margin-right: 96% !important;
}

.mr-97 {
	margin-right: 97% !important;
}

.mr-98 {
	margin-right: 98% !important;
}

.mr-99 {
	margin-right: 99% !important;
}

.mr-100 {
	margin-right: 100% !important;
}

.minh-0 {
	min-height: 0px !important;
}
.minh-10 {
	min-height: 10px !important;
}
.minh-20 {
	min-height: 20px !important;
}
.minh-30 {
	min-height: 30px !important;
}
.minh-40 {
	min-height: 40px !important;
}
.minh-50 {
	min-height: 50px !important;
}
.minh-60 {
	min-height: 60px !important;
}
.minh-70 {
	min-height: 70px !important;
}
.minh-80 {
	min-height: 80px !important;
}
.minh-90 {
	min-height: 90px !important;
}
.minh-100 {
	min-height: 100px !important;
}
.minh-110 {
	min-height: 110px !important;
}
.minh-120 {
	min-height: 120px !important;
}
.minh-130 {
	min-height: 130px !important;
}
.minh-140 {
	min-height: 140px !important;
}
.minh-150 {
	min-height: 150px !important;
}
.minh-160 {
	min-height: 160px !important;
}
.minh-170 {
	min-height: 170px !important;
}
.minh-180 {
	min-height: 180px !important;
}
.minh-190 {
	min-height: 190px !important;
}
.minh-200 {
	min-height: 200px !important;
}
.minh-210 {
	min-height: 210px !important;
}
.minh-220 {
	min-height: 220px !important;
}
.minh-230 {
	min-height: 230px !important;
}
.minh-240 {
	min-height: 240px !important;
}
.minh-250 {
	min-height: 250px !important;
}
.minh-260 {
	min-height: 260px !important;
}
.minh-270 {
	min-height: 270px !important;
}
.minh-280 {
	min-height: 280px !important;
}
.minh-290 {
	min-height: 290px !important;
}
.minh-300 {
	min-height: 300px !important;
}
.minh-310 {
	min-height: 310px !important;
}
.minh-320 {
	min-height: 320px !important;
}
.minh-330 {
	min-height: 330px !important;
}
.minh-340 {
	min-height: 340px !important;
}
.minh-350 {
	min-height: 350px !important;
}
.minh-360 {
	min-height: 360px !important;
}
.minh-370 {
	min-height: 370px !important;
}
.minh-380 {
	min-height: 380px !important;
}
.minh-390 {
	min-height: 390px !important;
}
.minh-400 {
	min-height: 400px !important;
}
.minh-410 {
	min-height: 410px !important;
}
.minh-420 {
	min-height: 420px !important;
}
.minh-430 {
	min-height: 430px !important;
}
.minh-440 {
	min-height: 440px !important;
}
.minh-450 {
	min-height: 450px !important;
}
.minh-460 {
	min-height: 460px !important;
}
.minh-470 {
	min-height: 470px !important;
}
.minh-480 {
	min-height: 480px !important;
}
.minh-490 {
	min-height: 490px !important;
}
.minh-500 {
	min-height: 500px !important;
}
.minh-510 {
	min-height: 510px !important;
}
.minh-520 {
	min-height: 520px !important;
}
.minh-530 {
	min-height: 530px !important;
}
.minh-540 {
	min-height: 540px !important;
}
.minh-550 {
	min-height: 550px !important;
}
.minh-560 {
	min-height: 560px !important;
}
.minh-570 {
	min-height: 570px !important;
}
.minh-580 {
	min-height: 580px !important;
}
.minh-590 {
	min-height: 590px !important;
}
.minh-600 {
	min-height: 600px !important;
}
.minh-610 {
	min-height: 610px !important;
}
.minh-620 {
	min-height: 620px !important;
}
.minh-630 {
	min-height: 630px !important;
}
.minh-640 {
	min-height: 640px !important;
}
.minh-650 {
	min-height: 650px !important;
}
.minh-660 {
	min-height: 660px !important;
}
.minh-670 {
	min-height: 670px !important;
}
.minh-680 {
	min-height: 680px !important;
}
.minh-690 {
	min-height: 690px !important;
}
.minh-700 {
	min-height: 700px !important;
}
.minh-710 {
	min-height: 710px !important;
}
.minh-720 {
	min-height: 720px !important;
}
.minh-730 {
	min-height: 730px !important;
}
.minh-740 {
	min-height: 740px !important;
}
.minh-750 {
	min-height: 750px !important;
}
.minh-760 {
	min-height: 760px !important;
}
.minh-770 {
	min-height: 770px !important;
}
.minh-780 {
	min-height: 780px !important;
}
.minh-790 {
	min-height: 790px !important;
}
.minh-800 {
	min-height: 800px !important;
}
.minh-810 {
	min-height: 810px !important;
}
.minh-820 {
	min-height: 820px !important;
}
.minh-830 {
	min-height: 830px !important;
}
.minh-840 {
	min-height: 840px !important;
}
.minh-850 {
	min-height: 850px !important;
}
.minh-860 {
	min-height: 860px !important;
}
.minh-870 {
	min-height: 870px !important;
}
.minh-880 {
	min-height: 880px !important;
}
.minh-890 {
	min-height: 890px !important;
}
.minh-900 {
	min-height: 900px !important;
}
.minh-910 {
	min-height: 910px !important;
}
.minh-920 {
	min-height: 920px !important;
}
.minh-930 {
	min-height: 930px !important;
}
.minh-940 {
	min-height: 940px !important;
}
.minh-950 {
	min-height: 950px !important;
}
.minh-960 {
	min-height: 960px !important;
}
.minh-970 {
	min-height: 970px !important;
}
.minh-980 {
	min-height: 980px !important;
}
.minh-990 {
	min-height: 990px !important;
}
.minh-1000 {
	min-height: 1000px !important;
}
.maxh-0 {
	max-height: 0px !important;
}
.maxh-10 {
	max-height: 10px !important;
}
.maxh-20 {
	max-height: 20px !important;
}
.maxh-30 {
	max-height: 30px !important;
}
.maxh-40 {
	max-height: 40px !important;
}
.maxh-50 {
	max-height: 50px !important;
}
.maxh-60 {
	max-height: 60px !important;
}
.maxh-70 {
	max-height: 70px !important;
}
.maxh-80 {
	max-height: 80px !important;
}
.maxh-90 {
	max-height: 90px !important;
}
.maxh-100 {
	max-height: 100px !important;
}
.maxh-110 {
	max-height: 110px !important;
}
.maxh-120 {
	max-height: 120px !important;
}
.maxh-130 {
	max-height: 130px !important;
}
.maxh-140 {
	max-height: 140px !important;
}
.maxh-150 {
	max-height: 150px !important;
}
.maxh-160 {
	max-height: 160px !important;
}
.maxh-170 {
	max-height: 170px !important;
}
.maxh-180 {
	max-height: 180px !important;
}
.maxh-190 {
	max-height: 190px !important;
}
.maxh-200 {
	max-height: 200px !important;
}
.maxh-210 {
	max-height: 210px !important;
}
.maxh-220 {
	max-height: 220px !important;
}
.maxh-230 {
	max-height: 230px !important;
}
.maxh-240 {
	max-height: 240px !important;
}
.maxh-250 {
	max-height: 250px !important;
}
.maxh-260 {
	max-height: 260px !important;
}
.maxh-270 {
	max-height: 270px !important;
}
.maxh-280 {
	max-height: 280px !important;
}
.maxh-290 {
	max-height: 290px !important;
}
.maxh-300 {
	max-height: 300px !important;
}
.maxh-310 {
	max-height: 310px !important;
}
.maxh-320 {
	max-height: 320px !important;
}
.maxh-330 {
	max-height: 330px !important;
}
.maxh-340 {
	max-height: 340px !important;
}
.maxh-350 {
	max-height: 350px !important;
}
.maxh-360 {
	max-height: 360px !important;
}
.maxh-370 {
	max-height: 370px !important;
}
.maxh-380 {
	max-height: 380px !important;
}
.maxh-390 {
	max-height: 390px !important;
}
.maxh-400 {
	max-height: 400px !important;
}
.maxh-410 {
	max-height: 410px !important;
}
.maxh-420 {
	max-height: 420px !important;
}
.maxh-430 {
	max-height: 430px !important;
}
.maxh-440 {
	max-height: 440px !important;
}
.maxh-450 {
	max-height: 450px !important;
}
.maxh-460 {
	max-height: 460px !important;
}
.maxh-470 {
	max-height: 470px !important;
}
.maxh-480 {
	max-height: 480px !important;
}
.maxh-490 {
	max-height: 490px !important;
}
.maxh-500 {
	max-height: 500px !important;
}
.maxh-510 {
	max-height: 510px !important;
}
.maxh-520 {
	max-height: 520px !important;
}
.maxh-530 {
	max-height: 530px !important;
}
.maxh-540 {
	max-height: 540px !important;
}
.maxh-550 {
	max-height: 550px !important;
}
.maxh-560 {
	max-height: 560px !important;
}
.maxh-570 {
	max-height: 570px !important;
}
.maxh-580 {
	max-height: 580px !important;
}
.maxh-590 {
	max-height: 590px !important;
}
.maxh-600 {
	max-height: 600px !important;
}
.maxh-610 {
	max-height: 610px !important;
}
.maxh-620 {
	max-height: 620px !important;
}
.maxh-630 {
	max-height: 630px !important;
}
.maxh-640 {
	max-height: 640px !important;
}
.maxh-650 {
	max-height: 650px !important;
}
.maxh-660 {
	max-height: 660px !important;
}
.maxh-670 {
	max-height: 670px !important;
}
.maxh-680 {
	max-height: 680px !important;
}
.maxh-690 {
	max-height: 690px !important;
}
.maxh-700 {
	max-height: 700px !important;
}
.maxh-710 {
	max-height: 710px !important;
}
.maxh-720 {
	max-height: 720px !important;
}
.maxh-730 {
	max-height: 730px !important;
}
.maxh-740 {
	max-height: 740px !important;
}
.maxh-750 {
	max-height: 750px !important;
}
.maxh-760 {
	max-height: 760px !important;
}
.maxh-770 {
	max-height: 770px !important;
}
.maxh-780 {
	max-height: 780px !important;
}
.maxh-790 {
	max-height: 790px !important;
}
.maxh-800 {
	max-height: 800px !important;
}
.maxh-810 {
	max-height: 810px !important;
}
.maxh-820 {
	max-height: 820px !important;
}
.maxh-830 {
	max-height: 830px !important;
}
.maxh-840 {
	max-height: 840px !important;
}
.maxh-850 {
	max-height: 850px !important;
}
.maxh-860 {
	max-height: 860px !important;
}
.maxh-870 {
	max-height: 870px !important;
}
.maxh-880 {
	max-height: 880px !important;
}
.maxh-890 {
	max-height: 890px !important;
}
.maxh-900 {
	max-height: 900px !important;
}
.maxh-910 {
	max-height: 910px !important;
}
.maxh-920 {
	max-height: 920px !important;
}
.maxh-930 {
	max-height: 930px !important;
}
.maxh-940 {
	max-height: 940px !important;
}
.maxh-950 {
	max-height: 950px !important;
}
.maxh-960 {
	max-height: 960px !important;
}
.maxh-970 {
	max-height: 970px !important;
}
.maxh-980 {
	max-height: 980px !important;
}
.maxh-990 {
	max-height: 990px !important;
}
.maxh-1000 {
	max-height: 1000px !important;
}
.minw-0 {
	min-width: 0px !important;
}
.minw-10 {
	min-width: 10px !important;
}
.minw-20 {
	min-width: 20px !important;
}
.minw-30 {
	min-width: 30px !important;
}
.minw-40 {
	min-width: 40px !important;
}
.minw-50 {
	min-width: 50px !important;
}
.minw-60 {
	min-width: 60px !important;
}
.minw-70 {
	min-width: 70px !important;
}
.minw-80 {
	min-width: 80px !important;
}
.minw-90 {
	min-width: 90px !important;
}
.minw-100 {
	min-width: 100px !important;
}
.minw-110 {
	min-width: 110px !important;
}
.minw-120 {
	min-width: 120px !important;
}
.minw-130 {
	min-width: 130px !important;
}
.minw-140 {
	min-width: 140px !important;
}
.minw-150 {
	min-width: 150px !important;
}
.minw-160 {
	min-width: 160px !important;
}
.minw-170 {
	min-width: 170px !important;
}
.minw-180 {
	min-width: 180px !important;
}
.minw-190 {
	min-width: 190px !important;
}
.minw-200 {
	min-width: 200px !important;
}
.minw-210 {
	min-width: 210px !important;
}
.minw-220 {
	min-width: 220px !important;
}
.minw-230 {
	min-width: 230px !important;
}
.minw-240 {
	min-width: 240px !important;
}
.minw-250 {
	min-width: 250px !important;
}
.minw-260 {
	min-width: 260px !important;
}
.minw-270 {
	min-width: 270px !important;
}
.minw-280 {
	min-width: 280px !important;
}
.minw-290 {
	min-width: 290px !important;
}
.minw-300 {
	min-width: 300px !important;
}
.minw-310 {
	min-width: 310px !important;
}
.minw-320 {
	min-width: 320px !important;
}
.minw-330 {
	min-width: 330px !important;
}
.minw-340 {
	min-width: 340px !important;
}
.minw-350 {
	min-width: 350px !important;
}
.minw-360 {
	min-width: 360px !important;
}
.minw-370 {
	min-width: 370px !important;
}
.minw-380 {
	min-width: 380px !important;
}
.minw-390 {
	min-width: 390px !important;
}
.minw-400 {
	min-width: 400px !important;
}
.minw-410 {
	min-width: 410px !important;
}
.minw-420 {
	min-width: 420px !important;
}
.minw-430 {
	min-width: 430px !important;
}
.minw-440 {
	min-width: 440px !important;
}
.minw-450 {
	min-width: 450px !important;
}
.minw-460 {
	min-width: 460px !important;
}
.minw-470 {
	min-width: 470px !important;
}
.minw-480 {
	min-width: 480px !important;
}
.minw-490 {
	min-width: 490px !important;
}
.minw-500 {
	min-width: 500px !important;
}
.minw-510 {
	min-width: 510px !important;
}
.minw-520 {
	min-width: 520px !important;
}
.minw-530 {
	min-width: 530px !important;
}
.minw-540 {
	min-width: 540px !important;
}
.minw-550 {
	min-width: 550px !important;
}
.minw-560 {
	min-width: 560px !important;
}
.minw-570 {
	min-width: 570px !important;
}
.minw-580 {
	min-width: 580px !important;
}
.minw-590 {
	min-width: 590px !important;
}
.minw-600 {
	min-width: 600px !important;
}
.minw-610 {
	min-width: 610px !important;
}
.minw-620 {
	min-width: 620px !important;
}
.minw-630 {
	min-width: 630px !important;
}
.minw-640 {
	min-width: 640px !important;
}
.minw-650 {
	min-width: 650px !important;
}
.minw-660 {
	min-width: 660px !important;
}
.minw-670 {
	min-width: 670px !important;
}
.minw-680 {
	min-width: 680px !important;
}
.minw-690 {
	min-width: 690px !important;
}
.minw-700 {
	min-width: 700px !important;
}
.minw-710 {
	min-width: 710px !important;
}
.minw-720 {
	min-width: 720px !important;
}
.minw-730 {
	min-width: 730px !important;
}
.minw-740 {
	min-width: 740px !important;
}
.minw-750 {
	min-width: 750px !important;
}
.minw-760 {
	min-width: 760px !important;
}
.minw-770 {
	min-width: 770px !important;
}
.minw-780 {
	min-width: 780px !important;
}
.minw-790 {
	min-width: 790px !important;
}
.minw-800 {
	min-width: 800px !important;
}
.minw-810 {
	min-width: 810px !important;
}
.minw-820 {
	min-width: 820px !important;
}
.minw-830 {
	min-width: 830px !important;
}
.minw-840 {
	min-width: 840px !important;
}
.minw-850 {
	min-width: 850px !important;
}
.minw-860 {
	min-width: 860px !important;
}
.minw-870 {
	min-width: 870px !important;
}
.minw-880 {
	min-width: 880px !important;
}
.minw-890 {
	min-width: 890px !important;
}
.minw-900 {
	min-width: 900px !important;
}
.minw-910 {
	min-width: 910px !important;
}
.minw-920 {
	min-width: 920px !important;
}
.minw-930 {
	min-width: 930px !important;
}
.minw-940 {
	min-width: 940px !important;
}
.minw-950 {
	min-width: 950px !important;
}
.minw-960 {
	min-width: 960px !important;
}
.minw-970 {
	min-width: 970px !important;
}
.minw-980 {
	min-width: 980px !important;
}
.minw-990 {
	min-width: 990px !important;
}
.minw-1000 {
	min-width: 1000px !important;
}
.maxw-0 {
	max-width: 0px !important;
}
.maxw-10 {
	max-width: 10px !important;
}
.maxw-20 {
	max-width: 20px !important;
}
.maxw-30 {
	max-width: 30px !important;
}
.maxw-40 {
	max-width: 40px !important;
}
.maxw-50 {
	max-width: 50px !important;
}
.maxw-60 {
	max-width: 60px !important;
}
.maxw-70 {
	max-width: 70px !important;
}
.maxw-80 {
	max-width: 80px !important;
}
.maxw-90 {
	max-width: 90px !important;
}
.maxw-100 {
	max-width: 100px !important;
}
.maxw-110 {
	max-width: 110px !important;
}
.maxw-120 {
	max-width: 120px !important;
}
.maxw-130 {
	max-width: 130px !important;
}
.maxw-140 {
	max-width: 140px !important;
}
.maxw-150 {
	max-width: 150px !important;
}
.maxw-160 {
	max-width: 160px !important;
}
.maxw-170 {
	max-width: 170px !important;
}
.maxw-180 {
	max-width: 180px !important;
}
.maxw-190 {
	max-width: 190px !important;
}
.maxw-200 {
	max-width: 200px !important;
}
.maxw-210 {
	max-width: 210px !important;
}
.maxw-220 {
	max-width: 220px !important;
}
.maxw-230 {
	max-width: 230px !important;
}
.maxw-240 {
	max-width: 240px !important;
}
.maxw-250 {
	max-width: 250px !important;
}
.maxw-260 {
	max-width: 260px !important;
}
.maxw-270 {
	max-width: 270px !important;
}
.maxw-280 {
	max-width: 280px !important;
}
.maxw-290 {
	max-width: 290px !important;
}
.maxw-300 {
	max-width: 300px !important;
}
.maxw-310 {
	max-width: 310px !important;
}
.maxw-320 {
	max-width: 320px !important;
}
.maxw-330 {
	max-width: 330px !important;
}
.maxw-340 {
	max-width: 340px !important;
}
.maxw-350 {
	max-width: 350px !important;
}
.maxw-360 {
	max-width: 360px !important;
}
.maxw-370 {
	max-width: 370px !important;
}
.maxw-380 {
	max-width: 380px !important;
}
.maxw-390 {
	max-width: 390px !important;
}
.maxw-400 {
	max-width: 400px !important;
}
.maxw-410 {
	max-width: 410px !important;
}
.maxw-420 {
	max-width: 420px !important;
}
.maxw-430 {
	max-width: 430px !important;
}
.maxw-440 {
	max-width: 440px !important;
}
.maxw-450 {
	max-width: 450px !important;
}
.maxw-460 {
	max-width: 460px !important;
}
.maxw-470 {
	max-width: 470px !important;
}
.maxw-480 {
	max-width: 480px !important;
}
.maxw-490 {
	max-width: 490px !important;
}
.maxw-500 {
	max-width: 500px !important;
}
.maxw-510 {
	max-width: 510px !important;
}
.maxw-520 {
	max-width: 520px !important;
}
.maxw-530 {
	max-width: 530px !important;
}
.maxw-540 {
	max-width: 540px !important;
}
.maxw-550 {
	max-width: 550px !important;
}
.maxw-560 {
	max-width: 560px !important;
}
.maxw-570 {
	max-width: 570px !important;
}
.maxw-580 {
	max-width: 580px !important;
}
.maxw-590 {
	max-width: 590px !important;
}
.maxw-600 {
	max-width: 600px !important;
}
.maxw-610 {
	max-width: 610px !important;
}
.maxw-620 {
	max-width: 620px !important;
}
.maxw-630 {
	max-width: 630px !important;
}
.maxw-640 {
	max-width: 640px !important;
}
.maxw-650 {
	max-width: 650px !important;
}
.maxw-660 {
	max-width: 660px !important;
}
.maxw-670 {
	max-width: 670px !important;
}
.maxw-680 {
	max-width: 680px !important;
}
.maxw-690 {
	max-width: 690px !important;
}
.maxw-700 {
	max-width: 700px !important;
}
.maxw-710 {
	max-width: 710px !important;
}
.maxw-720 {
	max-width: 720px !important;
}
.maxw-730 {
	max-width: 730px !important;
}
.maxw-740 {
	max-width: 740px !important;
}
.maxw-750 {
	max-width: 750px !important;
}
.maxw-760 {
	max-width: 760px !important;
}
.maxw-770 {
	max-width: 770px !important;
}
.maxw-780 {
	max-width: 780px !important;
}
.maxw-790 {
	max-width: 790px !important;
}
.maxw-800 {
	max-width: 800px !important;
}
.maxw-810 {
	max-width: 810px !important;
}
.maxw-820 {
	max-width: 820px !important;
}
.maxw-830 {
	max-width: 830px !important;
}
.maxw-840 {
	max-width: 840px !important;
}
.maxw-850 {
	max-width: 850px !important;
}
.maxw-860 {
	max-width: 860px !important;
}
.maxw-870 {
	max-width: 870px !important;
}
.maxw-880 {
	max-width: 880px !important;
}
.maxw-890 {
	max-width: 890px !important;
}
.maxw-900 {
	max-width: 900px !important;
}
.maxw-910 {
	max-width: 910px !important;
}
.maxw-920 {
	max-width: 920px !important;
}
.maxw-930 {
	max-width: 930px !important;
}
.maxw-940 {
	max-width: 940px !important;
}
.maxw-950 {
	max-width: 950px !important;
}
.maxw-960 {
	max-width: 960px !important;
}
.maxw-970 {
	max-width: 970px !important;
}
.maxw-980 {
	max-width: 980px !important;
}
.maxw-990 {
	max-width: 990px !important;
}
.maxw-1000 {
	max-width: 1000px !important;
}
.width-0vh {
	width: 0vh;
}
.width-1vh {
	width: 1vh;
}
.width-2vh {
	width: 2vh;
}
.width-3vh {
	width: 3vh;
}
.width-4vh {
	width: 4vh;
}
.width-5vh {
	width: 5vh;
}
.width-6vh {
	width: 6vh;
}
.width-7vh {
	width: 7vh;
}
.width-8vh {
	width: 8vh;
}
.width-9vh {
	width: 9vh;
}
.width-10vh {
	width: 10vh;
}
.width-11vh {
	width: 11vh;
}
.width-12vh {
	width: 12vh;
}
.width-13vh {
	width: 13vh;
}
.width-14vh {
	width: 14vh;
}
.width-15vh {
	width: 15vh;
}
.width-16vh {
	width: 16vh;
}
.width-17vh {
	width: 17vh;
}
.width-18vh {
	width: 18vh;
}
.width-19vh {
	width: 19vh;
}
.width-20vh {
	width: 20vh;
}
.width-21vh {
	width: 21vh;
}
.width-22vh {
	width: 22vh;
}
.width-23vh {
	width: 23vh;
}
.width-24vh {
	width: 24vh;
}
.width-25vh {
	width: 25vh;
}
.width-26vh {
	width: 26vh;
}
.width-27vh {
	width: 27vh;
}
.width-28vh {
	width: 28vh;
}
.width-29vh {
	width: 29vh;
}
.width-30vh {
	width: 30vh;
}
.width-31vh {
	width: 31vh;
}
.width-32vh {
	width: 32vh;
}
.width-33vh {
	width: 33vh;
}
.width-34vh {
	width: 34vh;
}
.width-35vh {
	width: 35vh;
}
.width-36vh {
	width: 36vh;
}
.width-37vh {
	width: 37vh;
}
.width-38vh {
	width: 38vh;
}
.width-39vh {
	width: 39vh;
}
.width-40vh {
	width: 40vh;
}
.width-41vh {
	width: 41vh;
}
.width-42vh {
	width: 42vh;
}
.width-43vh {
	width: 43vh;
}
.width-44vh {
	width: 44vh;
}
.width-45vh {
	width: 45vh;
}
.width-46vh {
	width: 46vh;
}
.width-47vh {
	width: 47vh;
}
.width-48vh {
	width: 48vh;
}
.width-49vh {
	width: 49vh;
}
.width-50vh {
	width: 50vh;
}
.width-51vh {
	width: 51vh;
}
.width-52vh {
	width: 52vh;
}
.width-53vh {
	width: 53vh;
}
.width-54vh {
	width: 54vh;
}
.width-55vh {
	width: 55vh;
}
.width-56vh {
	width: 56vh;
}
.width-57vh {
	width: 57vh;
}
.width-58vh {
	width: 58vh;
}
.width-59vh {
	width: 59vh;
}
.width-60vh {
	width: 60vh;
}
.width-61vh {
	width: 61vh;
}
.width-62vh {
	width: 62vh;
}
.width-63vh {
	width: 63vh;
}
.width-64vh {
	width: 64vh;
}
.width-65vh {
	width: 65vh;
}
.width-66vh {
	width: 66vh;
}
.width-67vh {
	width: 67vh;
}
.width-68vh {
	width: 68vh;
}
.width-69vh {
	width: 69vh;
}
.width-70vh {
	width: 70vh;
}
.width-71vh {
	width: 71vh;
}
.width-72vh {
	width: 72vh;
}
.width-73vh {
	width: 73vh;
}
.width-74vh {
	width: 74vh;
}
.width-75vh {
	width: 75vh;
}
.width-76vh {
	width: 76vh;
}
.width-77vh {
	width: 77vh;
}
.width-78vh {
	width: 78vh;
}
.width-79vh {
	width: 79vh;
}
.width-80vh {
	width: 80vh;
}
.width-81vh {
	width: 81vh;
}
.width-82vh {
	width: 82vh;
}
.width-83vh {
	width: 83vh;
}
.width-84vh {
	width: 84vh;
}
.width-85vh {
	width: 85vh;
}
.width-86vh {
	width: 86vh;
}
.width-87vh {
	width: 87vh;
}
.width-88vh {
	width: 88vh;
}
.width-89vh {
	width: 89vh;
}
.width-90vh {
	width: 90vh;
}
.width-91vh {
	width: 91vh;
}
.width-92vh {
	width: 92vh;
}
.width-93vh {
	width: 93vh;
}
.width-94vh {
	width: 94vh;
}
.width-95vh {
	width: 95vh;
}
.width-96vh {
	width: 96vh;
}
.width-97vh {
	width: 97vh;
}
.width-98vh {
	width: 98vh;
}
.width-99vh {
	width: 99vh;
}
.width-100vh {
	width: 100vh;
}
.height-0vh {
	height: 0vh;
}
.height-1vh {
	height: 1vh;
}
.height-2vh {
	height: 2vh;
}
.height-3vh {
	height: 3vh;
}
.height-4vh {
	height: 4vh;
}
.height-5vh {
	height: 5vh;
}
.height-6vh {
	height: 6vh;
}
.height-7vh {
	height: 7vh;
}
.height-8vh {
	height: 8vh;
}
.height-9vh {
	height: 9vh;
}
.height-10vh {
	height: 10vh;
}
.height-11vh {
	height: 11vh;
}
.height-12vh {
	height: 12vh;
}
.height-13vh {
	height: 13vh;
}
.height-14vh {
	height: 14vh;
}
.height-15vh {
	height: 15vh;
}
.height-16vh {
	height: 16vh;
}
.height-17vh {
	height: 17vh;
}
.height-18vh {
	height: 18vh;
}
.height-19vh {
	height: 19vh;
}
.height-20vh {
	height: 20vh;
}
.height-21vh {
	height: 21vh;
}
.height-22vh {
	height: 22vh;
}
.height-23vh {
	height: 23vh;
}
.height-24vh {
	height: 24vh;
}
.height-25vh {
	height: 25vh;
}
.height-26vh {
	height: 26vh;
}
.height-27vh {
	height: 27vh;
}
.height-28vh {
	height: 28vh;
}
.height-29vh {
	height: 29vh;
}
.height-30vh {
	height: 30vh;
}
.height-31vh {
	height: 31vh;
}
.height-32vh {
	height: 32vh;
}
.height-33vh {
	height: 33vh;
}
.height-34vh {
	height: 34vh;
}
.height-35vh {
	height: 35vh;
}
.height-36vh {
	height: 36vh;
}
.height-37vh {
	height: 37vh;
}
.height-38vh {
	height: 38vh;
}
.height-39vh {
	height: 39vh;
}
.height-40vh {
	height: 40vh;
}
.height-41vh {
	height: 41vh;
}
.height-42vh {
	height: 42vh;
}
.height-43vh {
	height: 43vh;
}
.height-44vh {
	height: 44vh;
}
.height-45vh {
	height: 45vh;
}
.height-46vh {
	height: 46vh;
}
.height-47vh {
	height: 47vh;
}
.height-48vh {
	height: 48vh;
}
.height-49vh {
	height: 49vh;
}
.height-50vh {
	height: 50vh;
}
.height-51vh {
	height: 51vh;
}
.height-52vh {
	height: 52vh;
}
.height-53vh {
	height: 53vh;
}
.height-54vh {
	height: 54vh;
}
.height-55vh {
	height: 55vh;
}
.height-56vh {
	height: 56vh;
}
.height-57vh {
	height: 57vh;
}
.height-58vh {
	height: 58vh;
}
.height-59vh {
	height: 59vh;
}
.height-60vh {
	height: 60vh;
}
.height-61vh {
	height: 61vh;
}
.height-62vh {
	height: 62vh;
}
.height-63vh {
	height: 63vh;
}
.height-64vh {
	height: 64vh;
}
.height-65vh {
	height: 65vh;
}
.height-66vh {
	height: 66vh;
}
.height-67vh {
	height: 67vh;
}
.height-68vh {
	height: 68vh;
}
.height-69vh {
	height: 69vh;
}
.height-70vh {
	height: 70vh;
}
.height-71vh {
	height: 71vh;
}
.height-72vh {
	height: 72vh;
}
.height-73vh {
	height: 73vh;
}
.height-74vh {
	height: 74vh;
}
.height-75vh {
	height: 75vh;
}
.height-76vh {
	height: 76vh;
}
.height-77vh {
	height: 77vh;
}
.height-78vh {
	height: 78vh;
}
.height-79vh {
	height: 79vh;
}
.height-80vh {
	height: 80vh;
}
.height-81vh {
	height: 81vh;
}
.height-82vh {
	height: 82vh;
}
.height-83vh {
	height: 83vh;
}
.height-84vh {
	height: 84vh;
}
.height-85vh {
	height: 85vh;
}
.height-86vh {
	height: 86vh;
}
.height-87vh {
	height: 87vh;
}
.height-88vh {
	height: 88vh;
}
.height-89vh {
	height: 89vh;
}
.height-90vh {
	height: 90vh;
}
.height-91vh {
	height: 91vh;
}
.height-92vh {
	height: 92vh;
}
.height-93vh {
	height: 93vh;
}
.height-94vh {
	height: 94vh;
}
.height-95vh {
	height: 95vh;
}
.height-96vh {
	height: 96vh;
}
.height-97vh {
	height: 97vh;
}
.height-98vh {
	height: 98vh;
}
.height-99vh {
	height: 99vh;
}
.height-100vh {
	height: 100vh;
}
.opacity-0 {
	opacity: 0 !important;
}
.opacity-0-1 {
	opacity: 0.1 !important;
}
.opacity-0-2 {
	opacity: 0.2 !important;
}
.opacity-0-3 {
	opacity: 0.3 !important;
}
.opacity-0-4 {
	opacity: 0.4 !important;
}
.opacity-0-5 {
	opacity: 0.5 !important;
}
.opacity-0-6 {
	opacity: 0.6 !important;
}
.opacity-0-7 {
	opacity: 0.7 !important;
}
.opacity-0-8 {
	opacity: 0.8 !important;
}
.opacity-0-9 {
	opacity: 0.9 !important;
}
.opacity-1-0 {
	opacity: 1 !important;
}
.opacity-0-15 {
	opacity: 0.15 !important;
}
.opacity-0-25 {
	opacity: 0.25 !important;
}
.opacity-0-35 {
	opacity: 0.35 !important;
}
.opacity-0-45 {
	opacity: 0.45 !important;
}
.opacity-0-55 {
	opacity: 0.55 !important;
}
.opacity-0-65 {
	opacity: 0.65 !important;
}
.opacity-0-75 {
	opacity: 0.75 !important;
}
.opacity-0-85 {
	opacity: 0.85 !important;
}
.opacity-0-95 {
	opacity: 0.95 !important;
}

.nav-bellCounter {
  animation: pulse 2s infinite;
}

.d-flex {
  display: flex !important;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(181, 44, 48, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(181, 44, 48, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(181, 44, 48, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(181, 44, 48, 0.4);
    box-shadow: 0 0 0 0 rgba(181, 44, 48, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(181, 44, 48, 0);
      box-shadow: 0 0 0 10px rgba(181, 44, 48, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(181, 44, 48, 0);
      box-shadow: 0 0 0 0 rgba(181, 44, 48, 0);
  }
}
